import { getPluginId } from '@grafana-incident-app/utils/pluginId';

import plugin from '../package.json';

export const CURRENT_VERSION = plugin.version;
export const PLUGIN_ROOT = `/a/${getPluginId()}`;
export const IS_DEV = process.env.NODE_ENV === 'development';
export const SLACK_CLIENT_ID = '2888862884.2048386887543';
export const FARO_DEV_URL =
  'https://faro-collector-prod-us-central-0.grafana.net/collect/f1ccb4e97b9633b7a32b9975418fd786';
export const FARO_OPS_URL =
  'https://faro-collector-prod-us-central-0.grafana.net/collect/3f76aee8b5f0b310cd4e3e0c71e06ede';
export const FARO_PROD_URL =
  'https://faro-collector-prod-us-central-0.grafana.net/collect/00a5e1de7a0db7a18f817503d8891955';
export const PREVIEW_INTEGRATIONS = ['grate.msTeams'];

export const TOP_EMOJI = [
  { emoji: '⭐' },
  { emoji: '🎯' },
  { emoji: '🤖' },
  { emoji: '👍' },
  { emoji: '👎' },
  { emoji: '🎉' },
  { emoji: '🔥' },
];
